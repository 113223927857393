<template>
  <div class="tab-contents-wrap">
    <div class="title-wrap">
      <h4>{{eventInfo.name}}</h4>
      <div class="header-info">
        <ul>
          <li class="date">
            {{ getDateString(eventInfo.startDate, eventInfo.endDate) }}
          </li>
          <li class="date v-bar">
            <template>
              {{eventInfo.placeName || eventInfo.placeDetail}}
            </template>
          </li>
        </ul>
      </div>
    </div>
    <template v-if="statusType === 1" >
      <div class="form-container">
        <ValidationObserver ref="form">
        <form>
          <fieldset>
            <legend>{{$t('content.event.Event.txt33')}}</legend>
            <ul>
              <li class="form-row">
                <ValidationProvider
                  :rules="{ required: true, phone: true }"
                  :name="$t('content.event.Event.txt34')"
                  v-slot="{ errors }"
                >
                  <TelInput
                    v-model="phone"
                    :label="$t('content.event.Event.txt34')"
                    :placeholder="$t('content.event.Event.txt35')"
                    :disabled="!enabledInput"
                    :class="{'error':errors[0]}"
                    required
                    maxlength="13"
                  />
                  <span v-if="errors[0]" class="input-error-desc">{{errors[0]}}</span>
                </ValidationProvider>
                <div class="checkbox-container">
                  <template v-if="enabledInput">
                    <BaseCheckbox
                      v-model="phoneInserted"
                    >{{$t('content.event.Event.txt36')}}
                    </BaseCheckbox>
                  </template>
                </div>
              </li>
              <li class="form-row">
                <BaseTextarea
                  v-model="memo"
                  :label="$t('content.event.Event.txt37')"
                  size="large"
                  :placeholder="$t('content.event.Event.txt38')"
                />
              </li>
            </ul>
          </fieldset>
        </form>
        </ValidationObserver>
        <div class="terms-area">
          <div class="terms-container">
            <ol>
              <li>{{$t('content.event.Event.txt39')}}</li>
              <li>{{$t('content.event.Event.txt40')}}</li>
              <li>
                {{$t('content.event.Event.txt41')}}
                <div class="period" v-html="$t('content.event.Event.txt42')">
                </div>
              </li>
            </ol>
          </div>
          <div class="checkbox-container">
            <BaseCheckbox
              v-model="agreed"
              required
            >{{$t('content.event.Event.txt43')}}
            </BaseCheckbox>
          </div>
        </div>
      </div>
      <!-- 버튼 -->
      <div class="btn-wrap">
        <a href="#" class="back" @click="goBack">Back</a>
        <button @click="apply" :disabled="!agreed" class="btn-basic">{{$t('content.event.Event.txt44')}}</button>
      </div>
      <!-- // 버튼 -->
    </template>
    <template v-else-if="statusType === 2">
      <!-- 이벤트 신청 성공결과 -->
      <div class="reservation-result-wrap">
          <div class="reservation-result" >
              <dl class="completed">
                  <dt>{{$t('content.event.Event.txt45')}}</dt>
                  <dd class="desc">
                  </dd>
              </dl>
          </div>
      </div>
      <div class="btn-wrap perspective">
        <button class="btn-ghost" @click="goEventCancel">{{$t('content.event.Event.txt46')}}</button>
        <button class="btn-basic" @click="goEventHistory">{{$t('content.event.Event.txt47')}}</button>
      </div>
      <!-- // 이벤트 신청 성공결과 -->
    </template>
    <template v-else>
      <!-- 이벤트 신청 에러결과 -->
      <div class="reservation-result-wrap">
          <div class="reservation-result">
              <dl class="failed">
                  <dt>{{$t('content.event.Event.txt48')}}</dt>
                  <dd class="desc" v-html="errMsg">
                  </dd>
              </dl>
          </div>
      </div>
      <!-- // 이벤트 신청 에러결과 -->
      <div class="btn-wrap perspective">
        <button class="btn-basic" @click="goConfirm">{{$t('content.event.Event.txt47')}}</button>
      </div>
    </template>
  </div>
</template>

<script>
import { APIs } from '@/common/portalApi';
import { mixin as mixinHelperUtils, mixinMotion, format } from '@/common/helperUtils';
import TelInput from '@/components/base/TelInput.vue';
import BaseCheckbox from '@/components/base/BaseCheckbox.vue';
import BaseTextarea from '@/components/base/BaseTextarea.vue';
import { ValidationObserver, ValidationProvider } from 'vee-validate';

export default {
  async beforeRouteLeave(to, from, next) {
    if (this.applyId) {
      next();
    } else if (this.statusType === 1 && this.eventInfo.applyEmail > 0) {
        next();
    } else {
      this.showConfirm(this.$t('content.event.Event.alertMsg.msg4'), () => {
        next();
      });
    }
  },
  name: 'EventApply',
  mixins: [mixinHelperUtils, mixinMotion],
  components: {
    BaseTextarea,
    BaseCheckbox,
    TelInput,
    ValidationObserver,
    ValidationProvider,
  },
  props: {
    /**
     * EventNo: 이벤트 항목번호
     */
    eventNo: {
      type: [Number, String],
      required: true,
    },
    /**
     * EventInfo: 이벤트 항목의 상세정보
     */
    eventInfo: {
      type: Object,
      required: true,
    },
    /**
     * EventDate: 이벤트 항목의 달
     */
    eventDate: {
      type: [Number, String],
      required: true,
    },
  },
  data() {
    return {
      statusType: 1,
      format,
      userInfo: undefined,
      phoneInserted: false,
      enabledInput: false,
      phone: '',
      memo: '',
      agreed: false,
      errMsg: '',
      applyId: null,
    };
  },
  created() {
    this.userInfo = this.$store.getters.getUserInfo();
    this.phone = this.userInfo.phone;
    this.enabledInput = (!this.phone || this.phone === '');
  },
  watch: {
    eventInfo: 'checkDuplicationReservation',
  },
  methods: {
    checkDuplicationReservation() {
      if (this.statusType === 1 && this.eventInfo.applyEmail > 0) {
        this.showAlert(this.$t('content.event.Event.alertMsg.msg1'), () => {
          this.$router.replace({ name: 'EventHistory' });
          this.$emit('input', 1);
        });
      }
    },
    apply() {
      this.validateForm(this.$refs.form).then(() => {
        this.portalApiClient({
          url: APIs.EVENT_APPLY,
          data: {
            eventId: this.eventInfo.id,
            memo: this.memo,
            memberId: this.userInfo.id,
            name: this.name || this.userInfo.name,
            email: this.email || this.userInfo.email,
            phone: this.phone,
            isChangeMemberPhone: this.phoneInserted,
          },
        }).then(({data}) => {
          if (this.phoneInserted) {
            this.$store.dispatch('setUserInfo', {
              phone: this.phone
            });
          }
          if (data.result) {
            this.applyId = data.data.id;
            this.statusType = 2;
            this.$store.commit('setPagestep', 10);
            /**
             * 이벤트 신청후 이벤트정보를 갱신해줘야
             * 신청여부를 판단할수 있음.
             */
            this.$parent.$emit('refresh');
          } else {
            this.statusType = 3;
            this.$store.commit('setPagestep', 10);
            this.errMsg = data.message;
          }
        });
      });
    },
    goEventHistory() {
      // this.$router.push({ name: 'EventHistory' });
      this.$emit('input', 1);
      this.$router.push({ name: 'EventHistory' });
    },
    goConfirm(event) {
      event.preventDefault();
      this.$router.replace({ name: 'EventDetail', params: { eventNo: this.eventNo } });
    },
    goBack(event) {
      event.preventDefault();
      this.showConfirm(this.$t('content.event.Event.alertMsg.msg4'), () => {
        this.$router.go(-1);
      });
    },
    goEventCancel() {
      this.showConfirm(this.$t('content.event.Event.alertMsg.msg5'), () => {
        this.portalApiClient({
          url: `${APIs.MYPAGE_EVENT_CANCEL}/${this.applyId}`,
          method: 'DELETE',
          data: {
            id: this.applyId
          },
        }).then(({ data }) => {
          if (data.result) {
            this.showAlert(this.$t('alertMessage.canceldEvent'));
            this.$router.push({
              name: 'EventDetail',
              params: {
                eventNo: this.eventNo,
              },
            });
          } else {
            this.showAlert(data.message);
          }
        });
      });
    },
    getDateString(startDate, endDate) {
      // 이벤트 기간 타이틀 문자열
      if (format.date(startDate) == format.date(endDate)) {
        return `${format.date(startDate)} ${format.time(this.$moment(startDate).format('HHmm'))} 
        - ${format.time(this.$moment(endDate).format('HHmm'))}`;
      } else {
        return `${format.date(startDate)} ${format.time(this.$moment(startDate).format('HHmm'))} 
        - ${format.date(endDate)} ${format.time(this.$moment(endDate).format('HHmm'))}`;
      }
    }
  }
};
</script>
